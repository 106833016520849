import { useNProgress } from '@vueuse/integrations/useNProgress'
import { useDebounceFn } from '@vueuse/core'
import usePagination from '~/composables/usePagination'
import type { PloneDocument } from '~/types/Plone'
import type { Ref } from 'vue'
import removeEmptyEntries from '~/utils/remove-empty-entries'

export default function (itemsPerPage: Ref<number> = ref(24)) {
  const abortControllers: Ref<AbortController[]> = ref([])
  const app = useNuxtApp()
  const { start, done } = useNProgress()
  const isLoading = ref(false)
  const data: Ref<PloneDocument> = ref(null)
  const items: Ref<PloneDocument[]> = ref([])
  const error: Ref<number | boolean> = ref(false)
  const totalItems = ref(0)
  const route = useRoute()
  const router = useRouter()
  const sort = ref('')

  const defaultParams = {
    show_images: 1,
    show_relations: 1,
    b_size: itemsPerPage.value,
    expand: 'breadcrumbs',
  }

  const {
    page,
    batchSize,
    batchStart,
  } = usePagination(itemsPerPage, totalItems)

  const debouncedFetch = useDebounceFn(() => fetchData(), 300)

  watch(() => route.query, debouncedFetch)
  watch(() => route.query, () => {
    if (route.query.sort) {
      sort.value = isArray(route.query.sort) ? route.query.sort[0] : route.query.sort
    } else {
      sort.value = null
    }
  })

  async function fetchData () {
    if (process.client) start()
    isLoading.value = true
    try {
      abortPreviousRequests()
      abortControllers.value.push(new AbortController())

      const res = await app.$plone(route.path, {
        params: getParams(),
      }, abortControllers.value[abortControllers.value.length - 1])

      if (res.redirectTo) {
        return await navigateTo(res.redirectTo, { redirectCode: 301 })
      }

      totalItems.value = res?.items_total
      data.value = res
      items.value = res?.items
      isLoading.value = false
      error.value = false
    } catch (e) {
      error.value = resolveErrorCode(e)
      data.value = {
        '@id': 'error',
        '@type': 'error',
        layout: 'error',
        title: `Error ${error.value}`,
        error_code: error.value,
      }
      isLoading.value = false
    }
    if (process.client) done()
  }

  function abortPreviousRequests () {
    if (abortControllers.value.length > 0) {
      abortControllers.value.forEach(controller => {
        if (!controller.signal.aborted) {
          controller.abort()
        }
      })
    }
  }

  function getParams () {
    const cleanParams = useOmit(route.query, ['page', 'sort'])
    const params = removeEmptyEntries(cleanParams)
    return {
      ...params,
      ...defaultParams,
      ...getPaginationParams(),
      ...getSortParams(),
    }
  }

  function getSortParams () {
    if (!sort.value) {
      return {}
    }

    const sortParts = sort.value.split(':')

    return {
      sort_on: sortParts[0],
      sort_order: sortParts[1],
    }
  }

  function getPaginationParams () {
    return {
      b_size: batchSize.value,
      b_start: batchStart.value,
    }
  }

  function resolveErrorCode (error) {
    if (error?.message.includes('401 Unauthorized')) {
      return 401
    }

    if (error?.message.includes('500')) {
      return 500
    }

    if (error?.message.includes('502')) {
      return 500
    }

    if (error?.message.includes('Failed to fetch')) {
      return 500
    }

    return 404
  }

  return {
    data,
    items,
    page,
    fetchData,
    totalItems,
    isLoading,
  }
}
